<template>
  <b-card no-body class="mb-0 bgCommon">
    <modal-deposit-record ref="refModalRecord" />
    <div class="m-2">
      <div
        class="
          d-flex
          flex-wrap flex-lg-nowrap
          justify-content-between
          align-items-center
        "
      >
        <h3 class="font-weight-bolder text-nowrap mb-0">
          <feather-icon
            style="margin-top: -3px"
            size="18"
            icon="DollarSignIcon"
          />
          信用卡充值
        </h3>
        <a
          href="javascript:void(0)"
          class="btn_record_list"
          @click="$bvModal.show('modal-deposit-record')"
        >
          <font-awesome-icon icon="fa-solid fa-sheet-plastic" />
          <p>充值記錄</p>
        </a>
      </div>
      <div class="d-flex mt-3 justify-content-center">
        <div class="exchange-form bgColor3">
          <b-form>
            <div class="row mx-0 form-item">
              <div class="col-9 text-nowrap">
                <label>花費</label>
                <b-form-input
                  id="company_phone"
                  type="number"
                  v-model="payData.money_amount"
                  placeholder="輸入金額"
                />
                <div class="error-text" v-if="!payData.money_amount || payData.money_amount < minAmount">
                  必需輸入金額 > {{minAmount}}
                </div>
              </div>
              <div class="col-3 d-flex text-nowrap align-items-end">
                <p></p>
                <div class="icon-tag">
                  <font-awesome-icon icon="fas fa-dollar-sign" />
                  HKD
                </div>
              </div>
            </div>
            <div class="row mx-0 form-item mt-1 mt-sm-3">
              <div class="col-9 text-nowrap">
                <label>收到</label>
                <b-form-input
                  id="company_phone"
                  disabled
                  v-model="receviceCoin"
                  placeholder="你會收到的幣額"
                />
              </div>
              <div class="col-3 d-flex text-nowrap align-items-end">
                <p></p>
                <div class="icon-tag">
                  <font-awesome-icon icon="fas fa-coins" />
                  Coins
                </div>
              </div>
            </div>
            <b-button
              id="paypal-button"
              class="mt-1 mt-sm-3 w-100"
              :disabled="(!payData.money_amount || payData.money_amount < minAmount)"
              :class="(!payData.money_amount || payData.money_amount < minAmount) ? 'disabled-click' : ''"
              type="submit"
              variant="out"
            ></b-button>
          </b-form>
        </div>
      </div>
    </div>
  </b-card>
</template>
<script>
import {
  BModal,
  BCard,
  BFormInput,
  BForm,
  BButton,
  BOverlay,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ModalDepositRecord from "./modal/ModalDepositRecord.vue";
import { useLocalStorage } from "@vueuse/core";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import axios from "axios";

export default {
  data() {
    return {
      payData: {money_amount:null},
    };
  },
  components: {
    BModal,
    BCard,
    BFormInput,
    BForm,
    BButton,
    BOverlay,
    BFormInvalidFeedback,
    ModalDepositRecord,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    receviceCoin() {
      if (isNaN(this.payData.money_amount)) {
        return "";
      } else {
        return this.payData.money_amount * 1;
      }
    },
    minAmount(){
      return Number(this.$store.state.app.user_info.info.minimum_paypal_amount) || 5000
    }
  },
  methods: {},
  mounted() {
    this.$refs.refModalRecord.fetchRecord();

    let that = this;
    paypal.Button.render(
      {
        env: "sandbox",
        client: {
          sandbox: process.env.VUE_APP_PAYPAL_CLIENT_ID ||
            "Af0wbLw8AI9yJ98LMe_q-AN5JXpxe3YD4-MH-wepjSxWI0W66Q3BdpEUgUiBuU161d3jNGA6iZbdI1Yw",
        },
        locale: "zh_HK",
        style: {
          size: "responsive",
          color: "gold",
          shape: "pill",
          label: "checkout",
          tagline: "true",
        },
        payment: function (resolve, rejcet) {
          const host = window.location.origin
          let returnUrl = host;
          let amount = that.payData.money_amount;
          let token = useLocalStorage("accessToken").value;
          //Here call your own API server
          let user = JSON.parse(useLocalStorage("userData").value);
          return new Promise((resolve, reject) => {
            axios
              .post(
                `${process.env.VUE_APP_SERVICE_URL}/paypal/checkout-paypal`,
                {
                  return_url: returnUrl,
                  supplier_id: user.id,
                  first_name: user.name,
                  // last_name: 'Lik Ho',
                  email: user.email,
                  // tel: '26612345',
                  // address: 'Tai Po',
                  amount: amount,
                },
                { headers: { Authorization: "Bearer " + token } }
              )
              .then((res) => {
                this.buyer_id = res.data.buyer_id;
                resolve(res.data.id);
              })
              .catch((error) => {
                reject(error);
              });
          });
        },
        onAuthorize: function (data) {
          // Execute the payment here, when the buyer approves the transaction
          return new Promise((resolve, reject) => {
            axios
              .post(
                `${process.env.VUE_APP_SERVICE_URL}/paypal/execute-paypal`,
                { payer_id: data.payerID, payment_id: data.paymentID },
                {
                  header: {
                    Authorization:
                      "Bearer " + useLocalStorage("accessToken").value,
                  },
                }
              )
              .then((res) => {
                resolve(res);
                that.$swal({
                  text: "充值成功！",
                  confirmButtonText: "確定",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });

                that.$refs.refModalRecord.fetchRecord();
                that.$store.dispatch("app/fetchCoinsBalance");
              })
              .catch((error) => {
                reject(error);
                this.$swal({
                  text: "充值失敗！",
                  icon: "error",
                  confirmButtonText: "確定",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          });
        },
      },
      "#paypal-button"
    );
  },
  setup() {
    const resetPayData = () => {
      this.payData = JSON.parse(JSON.stringify({}));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetPayData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>
<style lang="scss" scoped>
.card {
  min-height: 80vh;
}

.btn_record_list {
  font-size: 18px;
  color: #469b2c;

  svg {
    display: block;
    font-size: 30px;
    margin: 0.5rem auto;
  }
}

.exchange-form {
  // position: absolute;
  // left: 50%;
  // top: 25rem;
  // transform: translate(-50%, -50%);
  width: 100%;
  max-width: 560px;
  padding: 2rem;

  .form-item {
    padding: 1rem;
    // border-radius: 6px;
    background: #F0F0F0;
    // background: #fff9ea;

    label {
      color: #9097a0;
    }
    #company_phone {
      &.form-control {
        background-color: #fff;
      }
    }

    .icon-tag {
      padding-bottom: 0.7rem;
    }

    .receive {
      padding: 0.7rem;
    }
  }
}
@media screen and (max-width: 575px) {
  .exchange-form {
    padding: 1.5rem 1.2rem;
    #paypal-button {
      .paypal-button-container {
        min-width: 1px;
        max-width: 100vw;
      }
    }
  }
}
</style>