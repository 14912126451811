<template>
  <!-- modal deposit record-->
  <b-modal
    id="modal-deposit-record"
    cancel-variant="outline-secondary"
    centered
    size="lg"
    :ok-disabled="false"
    headerClass="px-3 py-2 border-bottom bgWhite"
    footerClass="px-3 py-2 border-top bgWhite"
  >

    <template #modal-header="{ close }">
      <h3 class="m-0">充值記錄</h3>
      <a @click="close()">
        <font-awesome-icon style="font-size: 20px" icon="fas fa-times" />
      </a>
    </template>
    <modal-deposit-detail-record :recordDetailsData="recordDetailsData" />
    <div v-if="recordData" class="">
      <div class="d-flex align-items-center">
        <div class="form-col-select mr-1">
          <v-select
            v-model="perPage"
            style="width: 100px"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block"
          />
          <feather-icon size="18" icon="ChevronDownIcon" />
        </div>
        <div class="search-primary w-100">
          <feather-icon size="16" icon="SearchIcon" />
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="搜尋交易編號或買家姓名"
          />
        </div>
      </div>
      <div class="mb-3">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-table
            ref="refRecordListTable"
            class="position-relative mt-1"
            :items="recordData"
            responsive
            :fields="recordTableColumns"
            primary-key="id"
            show-empty
            empty-text="未找到匹配的記錄"
          >
            <!-- Column: transaction_id -->
            <template #cell(transaction_id)="data">
              <a class="" href="javascript:void(0)" @click="triggerDetailModal(data.item)">
                {{ data.item.transaction_id }}
              </a>
            </template>
            <!-- Column: create time -->
            <template #cell(created_at)="data">
              <div class="d-flex">
                {{ getCorrectDateTime(data.item.created_at) }}
              </div>
            </template>
          </b-table>
        </b-overlay>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted"
                >在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至
                {{ dataMeta.to }}個記錄</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRecords"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <template #modal-footer="{ cancel }">
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          @click="cancel"
        >
          取消
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ref, watch, computed } from "@vue/composition-api";
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import ability from "@/libs/acl/ability";
import ModalDepositDetailRecord from "./ModalDepositDetailRecord.vue";
import {
  BModal,
  BOverlay,
  BButton,
  BTable,
  BFormInput,
  BCardBody,
  BCard,
  BRow,
  BCol,
  BPagination,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BOverlay,
    BButton,
    BTable,
    BFormInput,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BPagination,
    vSelect,
    ModalDepositDetailRecord,
  },
  data() {
    return {
      recordDetailsData:{}
    };
  },
  directives: {
    Ripple,
  },
  methods: {
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-deposit-record", "#btnShow");
    },
    triggerDetailModal(item){
      this.recordDetailsData = item;
       this.$root.$emit("bv::show::modal", "modal-deposit-detail-record", "#btnShow");
    },
  },
  setup() {
    const toast = useToast();
    const refRecordListTable = ref(null);
    const recordData = ref(null);
    const recordTableColumns = [
      { key: "transaction_id", label: "交易編號" },
      { key: "buyer_contact_name", label: "買家姓名" },
      { key: "amount", label: "花費港幣" },
      { key: "coin_amount", label: "獲得幣" },
      { key: "created_at", label: "交易時間" },
    ];
    const loading = ref(false)
    const perPage = ref(10);
    const totalRecords = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const dataMeta = computed(() => {
      const localItemsCount = refRecordListTable.value
        ? refRecordListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalRecords.value,
      };
    });
    watch([currentPage, perPage, searchQuery], () => {
      fetchRecord();
    });

    const fetchRecord = () => {
      loading.value = true
      axiosIns
        .get("/paypal/transaction/list", {
          params: {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
          },
        })
        .then((response) => {
          loading.value = false
          const { transactions, total } = response.data;
          recordData.value = transactions;
          totalRecords.value = total;
        })
        .catch((err) => {
          console.log(err);
          loading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching record list",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    return {
      recordData,
      fetchRecord,
      recordTableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRecordListTable,
      ability,
      loading
    };
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>